<template>
    <div class="wrap">
        <desLoad v-if="nomsg" :load="nomsg"></desLoad>
        <div class="content-box" v-else>
            <div class="box">
                <div class="top">
                    <div class="content">
                        <div class="user" @click.stop="goUser(m.userid)">
                            <img :src="rImg(m.head_100)" :onerror="txImg" class="tx" alt="" />
                            <div class="name-box">
                                <p class="name ellipsis-1">{{ m.name }}</p>
                                <p class="time">{{ m.addtime }}</p>
                            </div>
                        </div>
                        <h3 class="title" v-if="m.title">{{ m.title }}</h3>
                        <div class="dy-content">
                            <div class="tag-box">
                                <span class="tag" :key="ti" v-for="(t, ti) in m.topic">
                                    <router-link :to="{ path: '/list', query: {topic:t},}">{{ t }}</router-link>
                                </span>
                            </div>
                            <div class="tag-box">
                                <span class="tag" :key="ti" v-for="(t, ti) in m.topic_experience">
                                    <router-link :to="{ path: '/list', query: {topic:t, authed:1},}">&经验 {{ t }} 已认证</router-link>
                                </span>
                            </div>
                            <div class="dy-content-des" :class="{ limit: !m.paystate && m.price != 0 }" @click="openTxt()" v-html="dealTxt()"></div>
                        </div>
                        <div v-if="m.paystate || m.price == 0">
                            <div class="img-box" v-if="m.video" @click="openVideo(m.video)">
                                <el-image class="img video-img" :src="rImg(m.cover)" fit="cover">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                            <div class="img-box" v-else-if="m.masterimg && m.masterimg.length && m.masterimg[0]">
                                <el-image class="img" :src="rImg(item)" v-for="item in m.masterimg" :key="item" fit="contain">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                        </div>
                        <div v-if="m.tips" v-html="m.tips"></div>
                        <div class="flex-bt">
                            <div class="icon-box">
                                <div class="icon link" :class="{ active: m.isthumbsup }" @click.stop="zanFlag()">
                                    <i class="iconfont icon-dianzan"></i>
                                    <span>{{ m.num }}</span>
                                </div>
                                <div class="icon link" @click="input.show = 1">
                                    <i class="iconfont icon-xiaoxi"></i>
                                    <span>{{ m.comments || "评论" }}</span>
                                </div>
                                <div class="icon link" :class="{ active: m.islover }" @click.stop="scFlag()">
                                    <i class="iconfont icon-jushoucang" :class="{ 'icon-jushoucanggift': m.islover }"></i>
                                    <span>{{ m.islover ? "已收藏" : "收藏" }}</span>
                                </div>
                                <!-- <div class="icon">
                <i class="iconfont icon-liulan"></i>
                <span>{{ m.visit }}</span>
              </div> -->
                            </div>
                            <el-button round class="ping-btn" @click="input.show = !input.show">{{ input.show ? "收起" : "评论" }}</el-button>
                            <!-- <el-button round class="ping-btn" @click="openApp()">评论</el-button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="input-box box" v-show="input.show">
                <el-input type="textarea" maxlength="50" :show-word-limit="true" resize="none" rows="5" placeholder="友好评论,说说你的想法" v-model="textarea"></el-input>
                <div style="text-align: right">
                    <el-button round class="ping-btn" @click="setPing">确定</el-button>
                </div>
            </div>
            <h3 class="box-title">相关评论</h3>
            <div class="box" v-if="m.comments">
                <pDyList :list="pingList"></pDyList>
                <p v-if="m.comments>10" style="color: #aaa;">更多评论请前往APP查看</p>
            </div>
        </div>
    </div>
</template>

<script>
    import pDyList from "@components/common/comment-dy";
    import desLoad from "@components/common/des-load";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            pDyList,
            desLoad,
        },
        data() {
            return {
                nomsg: 1,
                id: "",
                m: {},
                textarea: "",
                input: {
                    show: 0,
                },
                list: [],
                myid: 0,
                shenhe: {
                    "-1": "未通过",
                    0: "审核中",
                },
                pingList: []
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.id = this.$route.query.id;
            if (!this.id) {
                this._home();
                return;
            }
            this.getData();
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            dealTxt() {
                let m = this.m;
                let c = m.content;
                if (c) {
                    c = c.replace(/http(s)*:\/\//gi, "//");
                }
                if (!m.paystate && m.price != 0) {
                    c += `<p class="art-price">支付${m.price}金币查看全文</p>`;
                }
                return c;
            },
            openTxt() {
                let m = this.m;
                if (!m.paystate && m.price != 0) {
                    this.openApp();
                }
            },
            // 获取详情
            async getData() {
                this.myid = this.$y_getKey("userid") || 0;
                let {
                    code,
                    msg,
                    result
                } = await this.$y_ajax("Home/Dymanic/dycommentlsts", {
                    dy_id: this.id,
                    userid: this.myid,
                });
                if (code == 200) {
                    this.m = result;
                    result.id = result.userid;
                    result.paystate = Number(result.paystate);
                    this.nomsg = 0;
                    this.setMeta(this.m.title);
                    if (result.comments) {
                        // 有评论数才请求评论列表
                        this.getPingList();
                    }
                } else {
                    this.nomsg = msg;
                }
            },
            // 点赞
            async zanFlag() {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                let s = parseInt(this.m.isthumbsup);
                let ret = await this.$y_ajax("api/Dymanic/thumbsup", {
                    dy_id: this.id,
                    type: s + 1, // 1点赞 2取消
                    userid: this.myid
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    let t = this.m.num;
                    this.m.isthumbsup = s == 0 ? 1 : 0;
                    this.m.num = s == 0 ? parseInt(t) + 1 : t - 1;
                }
            },
            // 收藏
            async scFlag() {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                let s = parseInt(this.m.islover);
                let ret = await this.$y_ajax("api/Dymanic/lover", {
                    dy_id: this.id,
                    type: s + 1, // 1点赞 2取消
                });
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    this.m.islover = s == 0 ? 1 : 0;
                }
            },
            // 评论
            async setPing() {
                if (!this.myid) {
                    this.$y_msg('请先登录');
                    return;
                }
                if (this.m.shenhe != undefined && this.m.shenhe != 1) {
                    this.$y_msg(this.shenhe[this.m.shenhe]);
                    return;
                }
                if (!this.textarea) {
                    this.$y_msg("请输入评论");
                    return;
                }
                let {
                    code,
                    msg
                } = await this.$y_ajax("home/Dymanic/comment", {
                    dy_id: this.id,
                    userid: this.myid,
                    touserid: this.m.userid,
                    type: 0,
                    content: this.textarea,
                });
                this.$y_msg(msg);
                if (code == 200) {
                    this.textarea = "";
                }
            },
            async getPingList() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/Dymanic/comments", {
                    dy_id: this.id,
                    page: 1,
                    pagesize: 10
                });
                if (code == 200) {
                    this.pingList = data;
                }
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            s_getUser() {
                this.getData();
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "@assets/less/des.less";

    .dy-content {
        position: relative;
    }
</style>
<style>
    .limit {
        max-height: 200px;
        overflow: hidden;
        min-height: 100px;
    }

    .art-price {
        cursor: pointer;
        text-align: center;
        height: 60%;
        padding-top: 20px;
        color: orange;
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        background: linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0.8));
    }

    .dy-content-des {
        line-height: 2;
    }

    .dy-content-des pre {
        white-space: normal;
    }

    .dy-content-des img {
        max-width: 100% !important;
        display: block;
        margin-bottom: 10px;
    }

    .dy-content-des .hide-img {
        display: none;
    }

    .dy-content-des span {
        display: inline;
    }
</style>